import { useCookie } from '#app';
import { useHexEncode } from '~/composables/useHex.js';

const COOKIE_NAME = 'consent';

export const useConsent = () => {
    const nuxtApp = useNuxtApp();
    const route = useRoute();

    const consentCookie = useCookie(COOKIE_NAME, {
        maxAge: 365 * 24 * 60 * 60,
        secure: true,
        sameSite: 'Strict',
        httpOnly: false,
    });

    const excludedRoutes = ['/impressum', '/nutzungsbedingungen', '/datenschutz'];

    const ssrContext = import.meta.server ? nuxtApp.ssrContext : {};
    const isCurrentPageExcluded = useState('isCurrentPageExcluded', () => false);
    const availableConsents = useState('availableConsents', () => []);
    const submittedConsents = useState('submittedConsents', () => ssrContext?.consents ?? []);
    const consentsSubmittedAt = useState('consentsSubmittedAt', () => ssrContext?.consentSubmittedAt ?? []);
    const consentPresent = useState('consentPresent', () => ssrContext?.consentPresent === true);

    if (!nuxtApp.$requiredConsentSlugs) {
        nuxtApp.$requiredConsentSlugs = ref([]);
    }

    if (!nuxtApp.$onConsentCallback) {
        nuxtApp.$onConsentCallback = ref(null);
    }

    const { data: fetchedAvailableConsents } = useAsyncData('consentData', () => $fetch('/api/frontpage/consent'));

    watch(
        () => fetchedAvailableConsents,
        (newData) => {
            if (newData.value) {
                availableConsents.value = newData.value.map((consent) => ({
                    id: consent.id,
                    required: !!consent.required,
                    name: consent.name,
                    slug: consent.slug,
                    description: consent.description,
                }));

                if (!consentPresent.value) {
                    submittedConsents.value = availableConsents.value.map((consent) => ({
                        id: consent.id,
                        slug: consent.slug,
                        accepted: consent.required,
                    }));
                }
            }
        },
        { immediate: true, deep: true }
    );

    watch(
        () => route.path,
        (newPath) => {
            isCurrentPageExcluded.value = excludedRoutes.some((routeRule) =>
                typeof routeRule === 'string' ? newPath === routeRule : routeRule.test(newPath)
            );
        },
        { immediate: true }
    );

    const getConsentById = (id) => submittedConsents.value.find((c) => c.id === id)?.accepted || false;

    const toggleConsent = (id, value) => {
        const consent = submittedConsents.value.find((c) => c.id === id);
        if (consent) {
            consent.accepted = value;
        }
    };

    const submitConsents = async (acceptedConsents = submittedConsents.value) => {
        const timestamp = new Date().toISOString();

        const consentData = {
            consents: acceptedConsents,
            submittedAt: timestamp,
        };

        consentsSubmittedAt.value = timestamp;

        submittedConsents.value = acceptedConsents;

        consentCookie.value = useHexEncode(JSON.stringify(consentData));
        consentPresent.value = true;

        if (nuxtApp.$onConsentCallback.value && areAllRequiredConsentsAccepted()) {
            nuxtApp.$onConsentCallback.value();
            nuxtApp.$onConsentCallback.value = null;
        }

        nuxtApp.$requiredConsentSlugs.value = [];
    };

    const submitSelectedConsents = async () => {
        await submitConsents(submittedConsents.value);
    };

    const submitAllAvailableConsents = async () => {
        const allConsents = availableConsents.value.map((consent) => ({
            id: consent.id,
            slug: consent.slug,
            accepted: true,
        }));
        await submitConsents(allConsents);
    };

    const submitOnlyRequiredCookies = async () => {
        const requiredConsents = availableConsents.value.map((consent) => ({
            id: consent.id,
            slug: consent.slug,
            accepted: consent.required,
        }));
        await submitConsents(requiredConsents);
    };

    const isConsentAccepted = (slug) => submittedConsents.value.find((c) => c.slug === slug)?.accepted || false;

    const isRevalidationExpired = () => {
        if (!consentCookie.value) return true;

        if (!consentsSubmittedAt.value) return true;

        const lastSubmittedAt = new Date(consentsSubmittedAt.value);
        const now = new Date();
        const timeSinceSubmission = now - lastSubmittedAt;

        const hasUnacceptedOptionalConsents = availableConsents.value.some(
            (consent) => !consent.required && !submittedConsents.value.some((c) => c.id === consent.id && c.accepted)
        );

        if (hasUnacceptedOptionalConsents) {
            const day = 24 * 60 * 60 * 1000;
            return timeSinceSubmission > day;
        }

        const year = 365 * 24 * 60 * 60 * 1000;
        return timeSinceSubmission > year;
    };

    const requestConsent = (onConsentGiven, requiredSlugs = []) => {
        const requiredSlugsAlreadyAccepted = requiredSlugs.some((requiredSlug) =>
            submittedConsents.value.some((consent) => consent.slug === requiredSlug && consent.accepted === true)
        );
        if (requiredSlugsAlreadyAccepted) {
            onConsentGiven.call(this);
        } else {
            nuxtApp.$requiredConsentSlugs.value = requiredSlugs;

            if (typeof onConsentGiven === 'function') {
                nuxtApp.$onConsentCallback.value = onConsentGiven;
            }
        }
    };

    const areAllRequiredConsentsAccepted = () => {
        return nuxtApp.$requiredConsentSlugs.value.every((slug) =>
            submittedConsents.value.some((c) => c.slug === slug && c.accepted)
        );
    };

    return {
        consentPresent,
        isCurrentPageExcluded,
        availableConsents,
        submittedConsents,
        requiredConsentSlugs: nuxtApp.$requiredConsentSlugs ?? [],
        isConsentAccepted,
        getConsentById,
        toggleConsent,
        submitSelectedConsents,
        submitAllAvailableConsents,
        submitOnlyRequiredCookies,
        isRevalidationExpired,
        requestConsent,
    };
};
